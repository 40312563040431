var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        dialogClass: "translate-modal",
        zIndex: 1001,
        width: 715,
        visible: _vm.visible,
        maskClosable: false,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal-title",
          attrs: { slot: "title" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "title",
        },
        [_vm._v("添加术语表")]
      ),
      _c(
        "div",
        { staticClass: "term-form", on: { mouseover: _vm.topCenterMouseover } },
        [
          _c(
            "a-form-model",
            _vm._b(
              {
                ref: "dataForm",
                staticStyle: { "padding-bottom": "70px" },
                attrs: {
                  layout: "horizontal",
                  model: _vm.formData,
                  rules: _vm.rules,
                },
              },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "分类", prop: "termTypeName" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择分类" },
                      on: { change: _vm.templateChange },
                      model: {
                        value: _vm.formData.termTypeName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "termTypeName", $$v)
                        },
                        expression: "formData.termTypeName",
                      },
                    },
                    _vm._l(_vm.templateNameList, function (i) {
                      return _c(
                        "a-select-option",
                        { key: i.id, attrs: { value: i.name } },
                        [
                          _vm._v(
                            "\n            " + _vm._s(i.name) + "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.formType == "人物"
                ? [
                    _c(
                      "a-form-model-item",
                      {
                        staticClass: "upload-item",
                        attrs: { label: "头像", prop: "subjectImgUrl" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "a-upload",
                              {
                                staticClass:
                                  "avatar-uploader avatar-uploader-first",
                                attrs: {
                                  name: "avatar",
                                  "list-type": "picture-card",
                                  "show-upload-list": false,
                                  "before-upload": (file) =>
                                    _vm.beforeCroUpload(file, 0),
                                },
                              },
                              [
                                _vm.formData.subjectImgUrl[0]
                                  ? _c("a-icon", {
                                      attrs: { type: "close-circle" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.clearUrl(0)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.formData.subjectImgUrl[0]
                                  ? _c("div", { staticClass: "avatar-show" }, [
                                      _c("img", {
                                        staticStyle: {
                                          width: "90px",
                                          height: "90px",
                                        },
                                        attrs: {
                                          src: _vm.formData.subjectImgUrl[0],
                                        },
                                      }),
                                    ])
                                  : _c(
                                      "div",
                                      [
                                        _c("a-icon", {
                                          attrs: {
                                            type: _vm.avatarLoading
                                              ? "loading"
                                              : "plus",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "ant-upload-text" },
                                          [_vm._v("主头像上传")]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                            _c("Editor", {
                              ref: "editor",
                              staticClass: "img-editor",
                              staticStyle: {
                                height: "30px",
                                overflow: "hidden",
                                width: "108px",
                                position: "relative",
                                top: "-1px",
                              },
                              attrs: {
                                defaultConfig: _vm.editorConfig,
                                mode: _vm.mode,
                              },
                              on: {
                                onCreated: _vm.onCreated,
                                customPaste: _vm.customPaste,
                              },
                              model: {
                                value: _vm.copyUrl1,
                                callback: function ($$v) {
                                  _vm.copyUrl1 = $$v
                                },
                                expression: "copyUrl1",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "a-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  name: "avatar",
                                  "list-type": "picture-card",
                                  "show-upload-list": false,
                                  "before-upload": (file) =>
                                    _vm.beforeCroUpload(file, 1),
                                },
                              },
                              [
                                _vm.formData.subjectImgUrl[1]
                                  ? _c("a-icon", {
                                      attrs: { type: "close-circle" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.clearUrl(1)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.formData.subjectImgUrl[1]
                                  ? _c("div", { staticClass: "avatar-show" }, [
                                      _c("img", {
                                        staticStyle: {
                                          width: "90px",
                                          height: "90px",
                                        },
                                        attrs: {
                                          src: _vm.formData.subjectImgUrl[1],
                                        },
                                      }),
                                    ])
                                  : _c(
                                      "div",
                                      [
                                        _c("a-icon", {
                                          attrs: {
                                            type: _vm.avatarLoading
                                              ? "loading"
                                              : "plus",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "ant-upload-text" },
                                          [_vm._v("上传")]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                            _c("Editor", {
                              ref: "editor",
                              staticClass: "img-editor",
                              staticStyle: {
                                height: "30px",
                                overflow: "hidden",
                                width: "106px",
                              },
                              attrs: {
                                defaultConfig: _vm.editorConfig2,
                                mode: _vm.mode,
                              },
                              on: {
                                onCreated: _vm.onCreated2,
                                customPaste: _vm.customPaste2,
                              },
                              model: {
                                value: _vm.copyUrl2,
                                callback: function ($$v) {
                                  _vm.copyUrl2 = $$v
                                },
                                expression: "copyUrl2",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                              "align-items": "center",
                            },
                          },
                          [
                            _c(
                              "a-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  name: "avatar",
                                  "list-type": "picture-card",
                                  "show-upload-list": false,
                                  "before-upload": (file) =>
                                    _vm.beforeCroUpload(file, 2),
                                },
                              },
                              [
                                _vm.formData.subjectImgUrl[2]
                                  ? _c("a-icon", {
                                      attrs: { type: "close-circle" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.clearUrl(2)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm.formData.subjectImgUrl[2]
                                  ? _c("div", { staticClass: "avatar-show" }, [
                                      _c("img", {
                                        staticStyle: {
                                          width: "90px",
                                          height: "90px",
                                        },
                                        attrs: {
                                          src: _vm.formData.subjectImgUrl[2],
                                        },
                                      }),
                                    ])
                                  : _c(
                                      "div",
                                      [
                                        _c("a-icon", {
                                          attrs: {
                                            type: _vm.avatarLoading
                                              ? "loading"
                                              : "plus",
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "ant-upload-text" },
                                          [_vm._v("上传")]
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                            _c("Editor", {
                              ref: "editor",
                              staticClass: "img-editor",
                              staticStyle: {
                                height: "30px",
                                overflow: "hidden",
                                width: "106px",
                              },
                              attrs: {
                                defaultConfig: _vm.editorConfig3,
                                mode: _vm.mode,
                              },
                              on: {
                                onCreated: _vm.onCreated3,
                                customPaste: _vm.customPaste3,
                              },
                              model: {
                                value: _vm.copyUrl3,
                                callback: function ($$v) {
                                  _vm.copyUrl3 = $$v
                                },
                                expression: "copyUrl3",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "other-item origin-other-item" }, [
                      _vm._v("\n          原文名\n        "),
                    ]),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "姓", prop: "originAll" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入姓" },
                          on: { change: _vm.originNameChange },
                          model: {
                            value: _vm.formData.originFamilyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "originFamilyName", $$v)
                            },
                            expression: "formData.originFamilyName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "名", prop: "originAll" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入名" },
                          on: { change: _vm.originNameChange },
                          model: {
                            value: _vm.formData.originGivenName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "originGivenName", $$v)
                            },
                            expression: "formData.originGivenName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "other-item" }, [
                      _vm._v("\n          译文名\n        "),
                    ]),
                    _c(
                      "a-form-model-item",
                      {
                        staticClass: "phonetic-item",
                        attrs: { label: "姓", prop: "processedName" },
                      },
                      [
                        (_vm.departName.indexOf("韩语部") > -1 &&
                          _vm.isDepart) ||
                        _vm.departName.indexOf("韩语部") == -1
                          ? _c(
                              "span",
                              [
                                _c("a-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { placeholder: "请输入姓" },
                                  model: {
                                    value: _vm.formData.processedFamilyName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "processedFamilyName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.processedFamilyName",
                                  },
                                }),
                                _vm.formData.phoneticFamilyNameTextList &&
                                _vm.formData.phoneticFamilyNameTextList.length
                                  ? _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.formData.phoneticFamilyNameTextList
                                                .map((p) => p.value)
                                                .join("")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("a-input", {
                              attrs: { placeholder: "请输入姓" },
                              model: {
                                value: _vm.formData.processedFamilyName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "processedFamilyName",
                                    $$v
                                  )
                                },
                                expression: "formData.processedFamilyName",
                              },
                            }),
                        _vm.departName == "日语部"
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: {
                                  loading: _vm.inputLoading,
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addPhonetic(
                                      _vm.formData.processedFamilyName,
                                      "processedFamilyName"
                                    )
                                  },
                                },
                              },
                              [_vm._v("获取注音")]
                            )
                          : _vm._e(),
                        _vm.departName.indexOf("韩语部") > -1 && _vm.isDepart
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.recommendText(
                                      _vm.formData.originName,
                                      "processedFamilyName"
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看推荐用字")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        staticClass: "phonetic-item",
                        attrs: { label: "名", prop: "processedName" },
                      },
                      [
                        (_vm.departName.indexOf("韩语部") > -1 &&
                          _vm.isDepart) ||
                        _vm.departName.indexOf("韩语部") == -1
                          ? _c(
                              "span",
                              [
                                _c("a-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { placeholder: "请输入名" },
                                  model: {
                                    value: _vm.formData.processedGivenName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "processedGivenName",
                                        $$v
                                      )
                                    },
                                    expression: "formData.processedGivenName",
                                  },
                                }),
                                _vm.formData.phoneticGivenNameTextList &&
                                _vm.formData.phoneticGivenNameTextList.length
                                  ? _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.formData.phoneticGivenNameTextList
                                                .map((p) => p.value)
                                                .join("")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("a-input", {
                              attrs: { placeholder: "请输入名" },
                              model: {
                                value: _vm.formData.processedGivenName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.formData,
                                    "processedGivenName",
                                    $$v
                                  )
                                },
                                expression: "formData.processedGivenName",
                              },
                            }),
                        _vm.departName == "日语部"
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: {
                                  loading: _vm.inputLoading,
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addPhonetic(
                                      _vm.formData.processedGivenName,
                                      "processedGivenName"
                                    )
                                  },
                                },
                              },
                              [_vm._v("获取注音")]
                            )
                          : _vm._e(),
                        _vm.departName.indexOf("韩语部") > -1 && _vm.isDepart
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.recommendText(
                                      _vm.formData.originName,
                                      "processedGivenName"
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看推荐用字")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        staticClass: "phonetic-item",
                        attrs: {
                          label: "略称（小名）",
                          prop: "originNickname",
                        },
                      },
                      [
                        (_vm.departName.indexOf("韩语部") > -1 &&
                          _vm.isDepart) ||
                        _vm.departName.indexOf("韩语部") == -1
                          ? _c(
                              "span",
                              [
                                _c("a-input", {
                                  staticStyle: { width: "200px" },
                                  attrs: { placeholder: "请输入略称" },
                                  model: {
                                    value: _vm.formData.originNickname,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "originNickname",
                                        $$v
                                      )
                                    },
                                    expression: "formData.originNickname",
                                  },
                                }),
                                _vm.formData.phoneticNickNameTextList &&
                                _vm.formData.phoneticNickNameTextList.length
                                  ? _c(
                                      "span",
                                      { staticStyle: { "margin-left": "5px" } },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.formData.phoneticNickNameTextList
                                                .map((p) => p.value)
                                                .join("")
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _c("a-input", {
                              attrs: { placeholder: "请输入略称" },
                              model: {
                                value: _vm.formData.originNickname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "originNickname", $$v)
                                },
                                expression: "formData.originNickname",
                              },
                            }),
                        _vm.departName == "日语部"
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: {
                                  loading: _vm.inputLoading,
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addPhonetic(
                                      _vm.formData.originNickname,
                                      "originNickname"
                                    )
                                  },
                                },
                              },
                              [_vm._v("获取注音")]
                            )
                          : _vm._e(),
                        _vm.departName.indexOf("韩语部") > -1 && _vm.isDepart
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.recommendText(
                                      _vm.formData.originName,
                                      "originNickname"
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看推荐用字")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.departName == "日语部"
                      ? _c(
                          "a-form-model-item",
                          {
                            attrs: { label: "注音情况", prop: "processedName" },
                          },
                          [
                            _c(
                              "a-radio-group",
                              {
                                model: {
                                  value: _vm.formData.phoneticStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "phoneticStatus",
                                      $$v
                                    )
                                  },
                                  expression: "formData.phoneticStatus",
                                },
                              },
                              [
                                _c(
                                  "a-radio",
                                  {
                                    staticStyle: { height: "30px" },
                                    attrs: { value: 0 },
                                  },
                                  [_vm._v("未注音")]
                                ),
                                _c(
                                  "a-radio",
                                  {
                                    staticStyle: { height: "30px" },
                                    attrs: { value: 1 },
                                  },
                                  [_vm._v("已注音")]
                                ),
                                _c(
                                  "a-radio",
                                  {
                                    staticStyle: { height: "30px" },
                                    attrs: { value: 2 },
                                  },
                                  [_vm._v("姓未注音")]
                                ),
                                _c(
                                  "a-radio",
                                  {
                                    staticStyle: { height: "30px" },
                                    attrs: { value: 3 },
                                  },
                                  [_vm._v("名未注音")]
                                ),
                                _c(
                                  "a-radio",
                                  {
                                    staticStyle: { height: "30px" },
                                    attrs: { value: 4 },
                                  },
                                  [_vm._v("每次注音")]
                                ),
                                _c(
                                  "a-radio",
                                  {
                                    staticStyle: { height: "30px" },
                                    attrs: { value: 5 },
                                  },
                                  [_vm._v("不需要注音")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "初次登场章节", prop: "debut" } },
                      [
                        _c("a-input-number", {
                          attrs: { min: 0, placeholder: "请输入初次登场章节" },
                          model: {
                            value: _vm.formData.debut,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "debut", $$v)
                            },
                            expression: "formData.debut",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "性别", prop: "gender" } },
                      [
                        _c(
                          "a-select",
                          {
                            attrs: { placeholder: "请选择性别" },
                            model: {
                              value: _vm.formData.gender,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "gender", $$v)
                              },
                              expression: "formData.gender",
                            },
                          },
                          [
                            _c(
                              "a-select-option",
                              { key: 1, attrs: { value: "男" } },
                              [_vm._v("\n              男\n            ")]
                            ),
                            _c(
                              "a-select-option",
                              { key: 2, attrs: { value: "女" } },
                              [_vm._v("\n              女\n            ")]
                            ),
                            _c(
                              "a-select-option",
                              { key: 3, attrs: { value: "无性别" } },
                              [_vm._v("\n              无性别\n            ")]
                            ),
                            _c(
                              "a-select-option",
                              { key: 4, attrs: { value: "待定" } },
                              [_vm._v("\n              待定\n            ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "身份（职业）", prop: "identity" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入身份" },
                          model: {
                            value: _vm.formData.identity,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "identity", $$v)
                            },
                            expression: "formData.identity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "自称" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入自称" },
                          model: {
                            value: _vm.formData.call,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "call", $$v)
                            },
                            expression: "formData.call",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "语气" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入语气" },
                          model: {
                            value: _vm.formData.tone,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "tone", $$v)
                            },
                            expression: "formData.tone",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.formData.termTypeName != "配角" &&
                    _vm.formData.termTypeName != "路人"
                      ? _vm._l(_vm.formData.characters, function (i, idx) {
                          return _c(
                            "a-form-model-item",
                            _vm._b(
                              {
                                key: idx + 1 + "xg",
                                attrs: { label: idx == 0 ? "性格" : "" },
                              },
                              "a-form-model-item",
                              idx === 0
                                ? _vm.layout
                                : {
                                    wrapperCol: {
                                      xs: { span: 24, offset: 0 },
                                      sm: { span: 18, offset: 6 },
                                    },
                                  },
                              false
                            ),
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "a-input-group",
                                    { attrs: { compact: "" } },
                                    [
                                      _c("a-input", {
                                        staticStyle: { width: "32%" },
                                        attrs: { placeholder: "请输入性格" },
                                        model: {
                                          value: i.name,
                                          callback: function ($$v) {
                                            _vm.$set(i, "name", $$v)
                                          },
                                          expression: "i.name",
                                        },
                                      }),
                                      _c("a-input", {
                                        staticStyle: { width: "68%" },
                                        attrs: {
                                          placeholder: "请输入性格描述",
                                        },
                                        model: {
                                          value: i.description,
                                          callback: function ($$v) {
                                            _vm.$set(i, "description", $$v)
                                          },
                                          expression: "i.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "end-btns",
                                      staticStyle: { top: "-5px" },
                                    },
                                    [
                                      _c("a-button", {
                                        staticClass: "item-btn",
                                        attrs: {
                                          disabled:
                                            _vm.formData.characters.length <= 1,
                                          shape: "circle",
                                          size: "small",
                                          icon: "minus",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delxg(
                                              _vm.formData.characters,
                                              idx
                                            )
                                          },
                                        },
                                      }),
                                      _c("a-button", {
                                        staticClass: "item-btn",
                                        attrs: {
                                          shape: "circle",
                                          size: "small",
                                          icon: "plus",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addxg(
                                              _vm.formData.characters,
                                              idx
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        })
                      : _vm._l(_vm.formData.characters, function (i, idx) {
                          return _c(
                            "a-form-model-item",
                            _vm._b(
                              {
                                key: idx + 1 + "xg",
                                attrs: { label: idx == 0 ? "性格" : "" },
                              },
                              "a-form-model-item",
                              idx === 0
                                ? _vm.layout
                                : {
                                    wrapperCol: {
                                      xs: { span: 24, offset: 0 },
                                      sm: { span: 18, offset: 6 },
                                    },
                                  },
                              false
                            ),
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "a-input-group",
                                    { attrs: { compact: "" } },
                                    [
                                      _c("a-input", {
                                        staticStyle: { width: "32%" },
                                        attrs: { placeholder: "请输入性格" },
                                        model: {
                                          value: i.name,
                                          callback: function ($$v) {
                                            _vm.$set(i, "name", $$v)
                                          },
                                          expression: "i.name",
                                        },
                                      }),
                                      _c("a-input", {
                                        staticStyle: { width: "68%" },
                                        attrs: {
                                          placeholder: "请输入性格描述",
                                        },
                                        model: {
                                          value: i.description,
                                          callback: function ($$v) {
                                            _vm.$set(i, "description", $$v)
                                          },
                                          expression: "i.description",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "end-btns",
                                      staticStyle: { top: "-5px" },
                                    },
                                    [
                                      _c("a-button", {
                                        staticClass: "item-btn",
                                        attrs: {
                                          disabled:
                                            _vm.formData.characters.length <= 1,
                                          shape: "circle",
                                          size: "small",
                                          icon: "minus",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delxg(
                                              _vm.formData.characters,
                                              idx
                                            )
                                          },
                                        },
                                      }),
                                      _c("a-button", {
                                        staticClass: "item-btn",
                                        attrs: {
                                          shape: "circle",
                                          size: "small",
                                          icon: "plus",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addxg(
                                              _vm.formData.characters,
                                              idx
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "说明", prop: "description" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "限制五十字以内" },
                          model: {
                            value: _vm.formData.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "description", $$v)
                            },
                            expression: "formData.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "是否有团体", prop: "tt" } },
                      [
                        _c(
                          "a-radio-group",
                          {
                            on: { change: _vm.teamRadioChange },
                            model: {
                              value: _vm.formData.hasTeam,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "hasTeam", $$v)
                              },
                              expression: "formData.hasTeam",
                            },
                          },
                          [
                            _c("a-radio", { attrs: { value: 1 } }, [
                              _vm._v("是"),
                            ]),
                            _c("a-radio", { attrs: { value: 2 } }, [
                              _vm._v("否"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.formData.hasTeam == 1
                      ? _vm._l(_vm.formData.teamList, function (item, idx) {
                          return _c(
                            "a-form-model-item",
                            _vm._b(
                              {
                                key: idx + 1 + "tt",
                                attrs: {
                                  prop: "tt",
                                  label: idx == 0 ? "团体" : "",
                                },
                              },
                              "a-form-model-item",
                              idx === 0
                                ? _vm.layout
                                : {
                                    wrapperCol: {
                                      xs: { span: 24, offset: 0 },
                                      sm: { span: 18, offset: 6 },
                                    },
                                  },
                              false
                            ),
                            [
                              _c(
                                "a-input-group",
                                { attrs: { compact: "" } },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      staticStyle: { width: "52%" },
                                      attrs: { placeholder: "请选择团体" },
                                      on: {
                                        change: (value) =>
                                          _vm.teamChange(value, item),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "dropdownRender",
                                            fn: function (menu) {
                                              return _c(
                                                "div",
                                                {},
                                                [
                                                  _c("v-nodes", {
                                                    attrs: { vnodes: menu },
                                                  }),
                                                  _c("a-divider", {
                                                    staticStyle: {
                                                      margin: "4px 0",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        padding: "4px 8px",
                                                        cursor: "pointer",
                                                      },
                                                      on: {
                                                        mousedown: (e) =>
                                                          e.preventDefault(),
                                                        click: _vm.openTeam,
                                                      },
                                                    },
                                                    [
                                                      _c("a-icon", {
                                                        attrs: { type: "plus" },
                                                      }),
                                                      _vm._v(
                                                        " 新建团体\n                  "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: item.team,
                                        callback: function ($$v) {
                                          _vm.$set(item, "team", $$v)
                                        },
                                        expression: "item.team",
                                      },
                                    },
                                    _vm._l(_vm.teamList, function (i) {
                                      return _c(
                                        "a-select-option",
                                        { key: i.id, attrs: { value: i.id } },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(i.originName) +
                                              "/" +
                                              _vm._s(i.processedName) +
                                              "\n                "
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _c("a-input", {
                                    staticStyle: { width: "48%" },
                                    attrs: {
                                      placeholder: "请输入在团体的职责",
                                    },
                                    model: {
                                      value: item.teamDescription,
                                      callback: function ($$v) {
                                        _vm.$set(item, "teamDescription", $$v)
                                      },
                                      expression: "item.teamDescription",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "end-btns",
                                  staticStyle: { top: "-14px" },
                                },
                                [
                                  _c("a-button", {
                                    staticClass: "item-btn",
                                    attrs: {
                                      disabled:
                                        _vm.formData.teamList.length <= 1,
                                      shape: "circle",
                                      size: "small",
                                      icon: "minus",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delTeam(
                                          _vm.formData.teamList,
                                          idx
                                        )
                                      },
                                    },
                                  }),
                                  _c("a-button", {
                                    staticClass: "item-btn",
                                    attrs: {
                                      shape: "circle",
                                      size: "small",
                                      icon: "plus",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addTeam(
                                          _vm.formData.teamList,
                                          idx
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "other-item",
                        on: {
                          click: function ($event) {
                            return _vm.openOher("top")
                          },
                        },
                      },
                      [
                        _c("a-icon", {
                          class:
                            _vm.showTop &&
                            _vm.formData.gxList &&
                            _vm.formData.gxList.length
                              ? "open-oher-item"
                              : "close-oher-item",
                          attrs: { type: "right" },
                        }),
                        _vm._v("\n          关系\n        "),
                      ],
                      1
                    ),
                    _vm.showTop &&
                    _vm.formData.gxList &&
                    _vm.formData.gxList.length
                      ? _c("div", { staticClass: "gx-list-show" }, [
                          _c("div", { staticStyle: { color: "red" } }, [
                            _vm._v("\n            注:填写单一关系。"),
                            _c("br"),
                            _vm._v(
                              "\n            例，此术语表是小明的术语表，小红是小明的妹妹，此时添加关系时，人物选择“小红”，关系填入“妹妹”，称呼填入小明对小红的称呼即可。\n          "
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "gx-list" },
                            [
                              _c(
                                "a-row",
                                { staticStyle: { "font-weight": "700" } },
                                [
                                  _c("a-col", { attrs: { span: 6 } }, [
                                    _vm._v("人物"),
                                  ]),
                                  _c("a-col", { attrs: { span: 5 } }, [
                                    _vm._v("关系"),
                                  ]),
                                  _c("a-col", { attrs: { span: 5 } }, [
                                    _vm._v("敬语/平语"),
                                  ]),
                                  _c("a-col", { attrs: { span: 8 } }, [
                                    _vm._v("称呼\n                "),
                                    !_vm.formData.gxList ||
                                    (_vm.formData.gxList &&
                                      !_vm.formData.gxList.length)
                                      ? _c(
                                          "div",
                                          { staticClass: "end-btns-table" },
                                          [
                                            _c("a-button", {
                                              staticClass: "item-btn",
                                              attrs: {
                                                disabled: true,
                                                shape: "circle",
                                                size: "small",
                                                icon: "minus",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delTeam(
                                                    _vm.formData.gxList,
                                                    _vm.index
                                                  )
                                                },
                                              },
                                            }),
                                            _c("a-button", {
                                              staticClass: "item-btn",
                                              attrs: {
                                                shape: "circle",
                                                size: "small",
                                                icon: "plus",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addgx(
                                                    _vm.formData.gxList,
                                                    -1
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                              _vm._l(
                                _vm.formData.gxList,
                                function (item, index) {
                                  return _c(
                                    "a-row",
                                    {
                                      key: index + 1 + "gx",
                                      staticClass: "gx-row",
                                    },
                                    [
                                      _c(
                                        "a-col",
                                        {
                                          attrs: { span: 6 },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editGxItem(
                                                _vm.formData.gxList,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.processedName || "")
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          attrs: { span: 5 },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editGxItem(
                                                _vm.formData.gxList,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item.name || ""))]
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          attrs: { span: 5 },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editGxItem(
                                                _vm.formData.gxList,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.honorific
                                                ? item.honorific == "其他"
                                                  ? `其他${
                                                      item.elseInfo
                                                        ? "，" + item.elseInfo
                                                        : ""
                                                    }`
                                                  : item.honorific
                                                : ""
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          attrs: { span: 8 },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editGxItem(
                                                _vm.formData.gxList,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.call) +
                                              "\n                "
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "end-btns-table" },
                                            [
                                              _c("a-button", {
                                                staticClass: "item-btn",
                                                attrs: {
                                                  shape: "circle",
                                                  size: "small",
                                                  icon: "minus",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.delTeam(
                                                      _vm.formData.gxList,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                              _c("a-button", {
                                                staticClass: "item-btn",
                                                attrs: {
                                                  shape: "circle",
                                                  size: "small",
                                                  icon: "plus",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.addgx(
                                                      _vm.formData.gxList,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "other-item",
                        on: {
                          click: function ($event) {
                            return _vm.openOher("bottom")
                          },
                        },
                      },
                      [
                        _c("a-icon", {
                          class: _vm.showBottom
                            ? "open-oher-item"
                            : "close-oher-item",
                          attrs: { type: "right" },
                        }),
                        _vm._v("\n          其他选填内容\n        "),
                      ],
                      1
                    ),
                    _vm.showBottom
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "职称", prop: "jobTitle" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.formData.jobTitle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "jobTitle", $$v)
                                },
                                expression: "formData.jobTitle",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showBottom
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "公司名称", prop: "companyName" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.formData.companyName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "companyName", $$v)
                                },
                                expression: "formData.companyName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showBottom
                      ? _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "爱称（褒义）",
                              prop: "goodNickname",
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.formData.goodNickname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "goodNickname", $$v)
                                },
                                expression: "formData.goodNickname",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showBottom
                      ? _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: "外号（贬义）",
                              prop: "badNickname",
                            },
                          },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.formData.badNickname,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "badNickname", $$v)
                                },
                                expression: "formData.badNickname",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showBottom
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "邮箱", prop: "email" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.formData.email,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "email", $$v)
                                },
                                expression: "formData.email",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showBottom
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "游戏ID", prop: "gameAccount" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.formData.gameAccount,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "gameAccount", $$v)
                                },
                                expression: "formData.gameAccount",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showBottom
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "手机号", prop: "mobile" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.formData.mobile,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "mobile", $$v)
                                },
                                expression: "formData.mobile",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showBottom
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "学校", prop: "school" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.formData.school,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "school", $$v)
                                },
                                expression: "formData.school",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showBottom
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "班级", prop: "clazz" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.formData.clazz,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "clazz", $$v)
                                },
                                expression: "formData.clazz",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showBottom
                      ? _c(
                          "a-form-model-item",
                          { attrs: { label: "武器", prop: "weapon" } },
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入内容" },
                              model: {
                                value: _vm.formData.weapon,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "weapon", $$v)
                                },
                                expression: "formData.weapon",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.formType == "团体"
                ? [
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "原文名", prop: "originName" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入原文名" },
                          model: {
                            value: _vm.formData.originName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "originName", $$v)
                            },
                            expression: "formData.originName",
                          },
                        }),
                        _vm.formData.originName &&
                        _vm.terms.filter(
                          (w) => _vm.formData.originName.indexOf(w.value) > -1
                        ).length
                          ? _c(
                              "div",
                              { staticClass: "check-origin" },
                              _vm._l(
                                _vm.terms.filter(
                                  (w) => _vm.formData.originName == w.value
                                ),
                                function (word) {
                                  return _c("div", { key: word.id }, [
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.openTerm(word)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(word.value + "→" + word.target)
                                        ),
                                      ]
                                    ),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        staticClass: "phonetic-item",
                        attrs: {
                          label: "译文名（全名）",
                          prop: "processedName",
                        },
                      },
                      [
                        _c(
                          "span",
                          [
                            _c("a-input", {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: "请输入译文名" },
                              model: {
                                value: _vm.formData.processedName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "processedName", $$v)
                                },
                                expression: "formData.processedName",
                              },
                            }),
                            _vm.formData.phoneticTextList &&
                            _vm.formData.phoneticTextList.length
                              ? _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.formData.phoneticTextList
                                            .map((p) => p.value)
                                            .join("")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.departName == "日语部"
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: {
                                  loading: _vm.inputLoading,
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addPhonetic(
                                      _vm.formData.processedName,
                                      "processedName"
                                    )
                                  },
                                },
                              },
                              [_vm._v("获取注音")]
                            )
                          : _vm._e(),
                        _vm.departName.indexOf("韩语部") > -1
                          ? _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "5px" },
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.recommendText(
                                      _vm.formData.originName,
                                      "processedName"
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看推荐用字")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.departName.indexOf("日语部") > -1
                      ? _c(
                          "a-form-model-item",
                          {
                            attrs: { label: "注音情况", prop: "processedName" },
                          },
                          [
                            _c(
                              "a-radio-group",
                              {
                                model: {
                                  value: _vm.formData.phoneticStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "phoneticStatus",
                                      $$v
                                    )
                                  },
                                  expression: "formData.phoneticStatus",
                                },
                              },
                              [
                                _c("a-radio", { attrs: { value: 0 } }, [
                                  _vm._v("未注音"),
                                ]),
                                _c("a-radio", { attrs: { value: 1 } }, [
                                  _vm._v("已注音"),
                                ]),
                                _c("a-radio", { attrs: { value: 4 } }, [
                                  _vm._v("每次注音"),
                                ]),
                                _c("a-radio", { attrs: { value: 5 } }, [
                                  _vm._v("不需要注音"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "初次登场章节", prop: "debut" } },
                      [
                        _c("a-input-number", {
                          attrs: { min: 0, placeholder: "请输入初次登场章节" },
                          model: {
                            value: _vm.formData.debut,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "debut", $$v)
                            },
                            expression: "formData.debut",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      { attrs: { label: "说明", prop: "description" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "限制五十字以内" },
                          model: {
                            value: _vm.formData.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "description", $$v)
                            },
                            expression: "formData.description",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("terms-cropper-model", {
            ref: "termsCropperModel",
            on: { upImg: _vm.upImg },
          }),
          _c("terms-team-modal", {
            ref: "termsTeamModel",
            attrs: {
              departName: _vm.departName,
              parent: _vm.taskDetail,
              productionId: _vm.taskDetail.productionId,
            },
            on: {
              afterSave: _vm.getTeamList,
              topCenterMouseover: _vm.topCenterMouseover,
            },
          }),
          _c("terms-gx-modal", {
            ref: "termsGxModel",
            attrs: { roleList: _vm.roleList },
            on: {
              topCenterMouseover: _vm.topCenterMouseover,
              editGx: _vm.editGx,
              saveGx: _vm.saveGx,
            },
          }),
          _c("terms-phonetic-modal", {
            ref: "termsPhoneticModal",
            on: {
              topCenterMouseover: _vm.topCenterMouseover,
              savePhonetic: _vm.savePhonetic,
            },
          }),
          _c("terms-recommend-modal", {
            ref: "termsRecommendModal",
            attrs: { parent: _vm.taskDetail },
            on: {
              topCenterMouseover: _vm.topCenterMouseover,
              saveRecommend: _vm.saveRecommend,
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { on: { mouseover: _vm.topCenterMouseover } },
          [
            _c("a-button", { key: "back", on: { click: _vm.handleCancel } }, [
              _vm._v("\n        取消\n      "),
            ]),
            _c(
              "a-button",
              {
                key: "submit",
                attrs: { type: "primary" },
                on: { click: _vm.handleOk },
              },
              [_vm._v("\n        确定\n      ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }